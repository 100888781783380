(function ($, window, document, undefined) {
  "use strict";

  var pluginName = 'slimmenu',
      defaults = {
        menuToggleButton: null,
        mobileMenuDisplayAtMinWidth: 992
      };

  function Plugin(element, options) {
    this.element = element;
    this.$elem = $(this.element);
    this.options = $.extend(defaults, options);
    this.init();
  }

  Plugin.prototype = {

    init: function() {
      var options = this.options,
          $menu = this.$elem,
          mobileMenuMediaQuery = "min-width: " + options.mobileMenuDisplayAtMinWidth + "px";


      options.menuToggleButton
        .on('touchstart', function(e) {
          $(this).data('detectTap', true);
        })
        .on('click touchend', function(e) {
          if(e.type === 'click' || $(this).data('detectTap')) {
            e.preventDefault();
            $menu.toggleClass('is-open');
            $(this).data('detectTap', false);
          }
        });


      $menu.find('li').has('ul').find('> a')
        .on('touchstart', function(e) {
          $(this).data('detectTap', true)
        })
        .on('click touchend', function(e) {
          if(window.matchMedia(mobileMenuMediaQuery).matches) {
            console.log("Desktop Menu");

            return true;
          } else {
            console.log("Mobile Menu");

            if(e.type === 'click' || $(this).data('detectTap')) {
              if($(this).siblings("ul").length > 0 && !$(this).parent('li').hasClass("is-open")) {
                $(this).parent('li').addClass("is-open")
                // Open on first click
                return false;
              } else {
                // Navigate on second click, or first click of something that doesn't have children
                return true;
              }
            }
          }
        })
        .on('mouseenter', function(e) {
          e.preventDefault();
          $(this).parents('li').addClass("is-open")
        })
        .on('mouseleave', function(e) {
          e.preventDefault();
          $(this).parents('li').removeClass("is-open")
        });
    }
  }

  $.fn[pluginName] = function(options) {
    return this.each(function () {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName,
          new Plugin(this, options));
      }
    });
  }

}(jQuery, window, document));