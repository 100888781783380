import $ from "jquery"
import { getCookie, createCookie } from "./util/cookies"
import { RichSet } from "./util/RichSet"

declare global {
  interface JQuery {
    enableBasketRemover()
    enableBasketControls()
  }
}

export class BasketItem {
  itemType: string
  itemKey: string
  containerKey: ContainerKey

  static fromElement(element): BasketItem[] {
    let itemType: string = element.data("basket-item-type")

    return element.data("basket-item-key")
      .toString()
      .split(" ")
      .map((itemKey) => new BasketItem(itemType, itemKey))
  }

  constructor(itemType: string, itemKey: string) {
    this.itemType = itemType
    this.itemKey = itemKey
    this.containerKey = itemType.toLowerCase() + "s" as ContainerKey
  }
}

export interface BasketStore {
  match(basketItems, callback)
  count(containerKey: string)
  totalCount()
  add(basketItems: BasketItem | BasketItem[], callback?: Function)
  remove(basketItem: BasketItem | BasketItem[], callback?: Function)
  contains(basketItem)
  clear(containerKey?: ContainerKey, itemKeys?: string[])
  onLoad(callback: Function)
}

type ContainerKey = "photos" | "videos" | "releases" | "collections"

class BasketInfo {
  _id: string = "guest"
  _rev: string | undefined = undefined
  photos: RichSet<string> = new RichSet()
  releases: RichSet<string> = new RichSet()
  collections: RichSet<string> = new RichSet()
  videos: RichSet<string> = new RichSet()
  lastModified: string = "1999-12-31T23:59:59.999Z"

  get(containerKey: ContainerKey) {
    return this[containerKey]
  }

  static fromDocument(basketDocument: Map<any, any>) {
    let info = new BasketInfo();
    info._id = basketDocument["_id"]
    info._rev = basketDocument["_rev"]
    info.photos = new RichSet<string>(basketDocument["photos"])
    info.videos = new RichSet<string>(basketDocument["videos"])
    info.releases = new RichSet<string>(basketDocument["releases"])
    info.collections = new RichSet<string>(basketDocument["collections"])
    info.lastModified = basketDocument["lastModified"]

    return info
  }
}

function Set_toJSON(key, value) {
  if (typeof value === 'object' && value instanceof Set) {
    return [...value];
  }
  return value;
}

// Manages basket items in HTML5 localStorage
export class LocalBasketStore implements BasketStore {
  storageKey = "basket"
  data: BasketInfo = new BasketInfo()

  constructor() {
    try {
      let storedData = JSON.parse(window.localStorage.getItem(this.storageKey) as string)
      if (this.data === null || (typeof this.data !== "object")) {
        this.data = new BasketInfo()
      } else {
        this.data = BasketInfo.fromDocument(storedData)
      }
    } catch(e) {
      this.data = new BasketInfo()
    }

  }

  toJson(): string {
    return JSON.stringify(this.data, Set_toJSON)
  }

  save() {
    console.log("Saving Basket to localStorage", this.data, JSON.stringify(this.data))

    localStorage.setItem(this.storageKey, this.toJson())
  }

  match(basketItems, callback) {
    let matchingItems = basketItems.filter((basketItem) => {
      return this.data.get(basketItem.containerKey) !== undefined &&
        this.data.get(basketItem.containerKey).has(basketItem.itemKey)
    })

    callback(matchingItems)
  }

  count(containerKey: ContainerKey) {
    return this.data.get(containerKey).size
  }

  totalCount() {
    return this.data.get("photos").size + this.data.get("videos").size + this.data.get("releases").size + this.data.get("collections").size
  }

  add(basketItems: BasketItem | BasketItem[], callback?: Function) {
    console.log("LocalBasketStore#add ", basketItems)

    let items: BasketItem[]

    if(basketItems instanceof BasketItem) {
      items = [basketItems]
    } else {
      items = basketItems
    }

    items.forEach((item) => {
      if (!this.data.get(item.containerKey).has(item.itemKey)) {
        this.data.get(item.containerKey).add(item.itemKey)
        this.save()

        callback!(this)
      }
    })
  }

  remove(basketItems: BasketItem | BasketItem[], callback?: Function) {
    console.log("LocalBasketStore#remove ", basketItems)

    let items: BasketItem[]

    if(basketItems instanceof BasketItem) {
      items = [basketItems]
    } else {
      items = basketItems
    }

    items.forEach(basketItem => {
      this.data.get(basketItem.containerKey).delete(basketItem.itemKey)
    })

    this.save()

    callback!(this)
  }

  replace(newData: BasketInfo) {
    this.data = newData

    this.save()
  }

  contains(basketItem) {
    return this.data.get(basketItem.containerKey).has(basketItem.itemKey)
  }

  clear(containerKey?: ContainerKey, itemKeys?: string[]) {
    if (containerKey === undefined) {
      this.data = new BasketInfo()
    } else {
      if(itemKeys !== undefined) {
        let container = this.data.get(containerKey);
        itemKeys.forEach(itemKey => container.delete(itemKey))
      } else {
        this.data.get(containerKey).clear()
      }
    }

    this.save()

    return $.Deferred(function(defer) {

    }).resolve().promise()
  }

  post(url: string, name: string = "basket") {
    let $form = $("<form/>", { method: "post", action: url })
    $form.append($("<input>", { type: "hidden", name: name, value: this.toJson() }))
    $form.appendTo('body').trigger("submit");
  }

  onLoad(callback: Function) {
    callback()
  }

}

// Manages basket items in both HTML5 localStorage and via a JSON API
export class RemoteBasketStore implements BasketStore {
  basketRoutePrefix: string = ""
  marketPrefix: string | undefined
  localBasketStore: LocalBasketStore
  loader: JQuery.jqXHR<any>

  constructor(localBasketStore: LocalBasketStore) {
    this.localBasketStore = localBasketStore
    this.marketPrefix = $("body").data("market-route-prefix")

    if (typeof(this.marketPrefix) === "string") {
      this.basketRoutePrefix = "/" + this.marketPrefix
      console.info("MarketPrefix found body[data-market-route-prefix]", this.marketPrefix)
    } else {
      console.warn("Market Prefix not found in body[data-market-route-prefix]")
    }

    // When we start up, ask for the remote basket document.
    this.loader = $.get(this.basketRoutePrefix + "/basket.json")
      .done(function(response) {
        localBasketStore.replace(BasketInfo.fromDocument(response))
      })
  }

  match(basketItems, callback) {
    this.loader.done((response) => {
      this.localBasketStore.match(basketItems, callback)
    })
  }

  count(containerKey) {
    return this.localBasketStore.count(containerKey)
  }

  totalCount() {
    return this.localBasketStore.totalCount()
  }

  add(basketItems: BasketItem | BasketItem[], callback?: Function) {
    console.log("RemoteBasketStore#add", basketItems)

    $.ajax({
        url: this.basketRoutePrefix + "/basket/items.json",
        type: "POST",
        data: JSON.stringify(basketItems),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
    }).done((response) => {
      this.localBasketStore.replace(BasketInfo.fromDocument(response))
      callback!(this)
    })
  }

  remove(basketItem: BasketItem | BasketItem[], callback?: Function) {
    console.log("RemoteBasketStore#remove", basketItem)

    $.ajax({
        url: this.basketRoutePrefix + "/basket/items/remove.json",
        type: "POST",
        data: JSON.stringify(basketItem),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
    }).done((response) => {
      this.localBasketStore.replace(BasketInfo.fromDocument(response))
      callback!(this)
    })
  }

  contains(basketItem) {
    return this.localBasketStore.contains(basketItem)
  }

  clear(containerKey?: ContainerKey, itemKeys?: string[]) {
    console.log("RemoteBasketStore#clear")

    if (containerKey === undefined) {
      return $.get(this.basketRoutePrefix + "/basket/clear").done((response) => {
        this.localBasketStore.replace(BasketInfo.fromDocument(response))
      })
    } else {
      if(itemKeys === undefined) {
        return $.get(this.basketRoutePrefix + "/basket/" + containerKey + "/clear").done((response) => {
          this.localBasketStore.replace(BasketInfo.fromDocument(response))
        })
      } else {
        return $.get(this.basketRoutePrefix + "/basket/" + containerKey + "/clear?clearIds=" + itemKeys.join(",")).done((response) => {
          this.localBasketStore.replace(BasketInfo.fromDocument(response))
        })
      }
    }
  }

  onLoad(callback: Function) {
    this.loader.done(() => { callback() })
  }

}


export class Basket {
  dataStore: BasketStore

  manageMultiBasketControls() {
    $("[data-basket-multi]").each((i, e) => {
      let controlledItems = BasketItem.fromElement($(e))
      console.log(e, "Controlled Items", controlledItems)

      this.dataStore.match(controlledItems, (matchingBasketItems) => {
        let controlledSet = new RichSet(controlledItems)
        let matchedSet = new RichSet(matchingBasketItems)

        if(controlledSet.difference(matchedSet).size == 0) {
          console.log(e, "Enabling Remover because controlledSet == matchesSet", controlledSet, matchedSet)
          // The basket contains all of the controlled items - activate the remove control
          this.enableMultiBasketRemover($(e))
        } else {
          console.log(e, "Not enabling remover because controlledSet != matchedSet", controlledSet, matchedSet, controlledSet.difference(matchedSet).size)
          this.enableMultiBasketAdder($(e))
        }

      })
    })
  }

  enableBasketRemoverForAllItemsOnPage() {
    // Scan the page for all items that are able to be added to/removed from the basket
    let itemsOnPage = $(":not([data-basket-multi])[data-basket-item-key]").map((i, e) => {
      return BasketItem.fromElement($(e))
    }).toArray()

    // Ask `dataStore` for the list of keys in the basket
    this.dataStore.match(itemsOnPage, (matchingBasketItems) => {
      $(document).trigger("basket.change", [this.dataStore.totalCount()])

      // `dataStore` fires off the callback when its found matches between itemsOnPage and
      // it's own internal set.
      matchingBasketItems.forEach((basketItem) => {
        this.enableBasketRemover(basketItem)
      })
    })

    this.manageMultiBasketControls()
  }

  count(containerKey) {
    return this.dataStore.count(containerKey)
  }

  totalCount() {
    return this.dataStore.totalCount()
  }

  contains(basketItem) {
    return this.dataStore.contains(basketItem)
  }


  clear(containerKey?: ContainerKey, itemKeys?: string[]) {
    let clearPromise = this.dataStore.clear(containerKey, itemKeys)

    return clearPromise.done(() => {
      $(document).trigger("basket.change", [this.dataStore.totalCount()])
      $(document).trigger("basket.clear", [containerKey, itemKeys])
      document.location.reload()
    })
  }

  enableBasketAdder(basketItem) {
    $("[data-basket-item-key=\"" + basketItem.itemKey + "\"]")
      .find("[data-basket-action=\"remove\"]").addClass("basket-action-hidden").removeClass("basket-action-visible")
      .siblings("[data-basket-action=\"add\"]").addClass("basket-action-visible").removeClass("basket-action-hidden")
  }

  enableBasketRemover(basketItem: BasketItem) {
    basketItem.itemKey.split(" ").forEach(function(id){
      $("[data-basket-item-key=\"" + id + "\"]").enableBasketRemover()
    })
  }

  enableMultiBasketAdder($e: JQuery) {
    $e.find("[data-basket-action=\"remove-multi\"]").addClass("basket-action-hidden").removeClass("basket-action-visible")
      .siblings("[data-basket-action=\"add-multi\"]").addClass("basket-action-visible").removeClass("basket-action-hidden")
  }

  enableMultiBasketRemover($e: JQuery) {
    $e.find("[data-basket-action=\"add-multi\"]").addClass("basket-action-hidden").removeClass("basket-action-visible")
      .siblings("[data-basket-action=\"remove-multi\"]").addClass("basket-action-visible").removeClass("basket-action-hidden")
  }

  onReady(callback) {
    this.dataStore.onLoad((basketInfo) => {
      callback(this);
    })
  }

  constructor(dataStore: BasketStore) {
    this.dataStore = dataStore
    let self = this;

    $(document).on("click", "[data-basket-action]", function(e) {
      let action = $(this).data('basket-action')

      if (action === "add" || action === "remove") {
        BasketItem.fromElement($(this).parents("[data-basket-item-key]")).forEach((basketItem) => {
          $(document).trigger('basket.' + action, basketItem)
        })
      } else if (action === "add-multi") {
        let basketItems = BasketItem.fromElement($(this).parents("[data-basket-item-key]"))
        dataStore.add(basketItems, () => {
          basketItems.forEach(item => self.enableBasketRemover(item))
          self.manageMultiBasketControls()
        })
      } else if (action === "remove-multi") {
        let basketItems = BasketItem.fromElement($(this).parents("[data-basket-item-key]"))
        dataStore.remove(basketItems, () => {
          basketItems.forEach(item => self.enableBasketAdder(item))
          self.manageMultiBasketControls()
        })
      }

      return true
    })

    $(document).on("basket.add", (e, basketItem) => {
      console.log("basket.add", basketItem)
      dataStore.add(basketItem, (store) => {
        $(document).trigger("basket.change", [store.totalCount()])
        this.manageMultiBasketControls()
      })
      this.enableBasketRemover(basketItem)
    })

    $(document).on("basket.remove", (e, basketItem) => {
      console.log("basket.remove", basketItem)
      dataStore.remove(basketItem, (store) => {
        $(document).trigger("basket.change", [store.totalCount()])
        this.manageMultiBasketControls()
      })
      this.enableBasketAdder(basketItem)
    })

    this.enableBasketRemoverForAllItemsOnPage()
  }
}

jQuery.fn.extend(
  {
    enableBasketRemover: function() {
      this.each(function() {
        $(this)
            .find("[data-basket-action=\"add\"]").addClass("basket-action-hidden").removeClass("basket-action-visible")
            .siblings("[data-basket-action=\"remove\"]").addClass("basket-action-visible").removeClass("basket-action-hidden")
    })
  },

  enableBasketControls: function() {
    this.each(function(){
      $(this).find("[data-basket-item-key]").addBack("[data-basket-item-key]").each(function(){
        BasketItem.fromElement($(this)).forEach((basketItem) => {
          if (window.basket.contains(basketItem)) {
            $(this).enableBasketRemover()
          }
        })
      })
    })
    return this
  }
})



// $("a#empty-basket").on("click",function(e){
//   window.basket.clear().done(function() {
//     document.location.reload()
//   })
// })

// $(document).on("click", "a.basket-download", function(e){
//   let isGuestBasket = $(this).hasClass("license-check-post-download")
//   let postUrl = $(this).attr("href")
//   if (window.location.href.match(/\/basket\/guest$/) && getCookie("newsroom.accepted_license") != "1") {
//     e.preventDefault()
//     let target = $(this).attr("href")
//
//     $.get("/licenses/default")
//       .done(function(modalBody) {
//         $("#licenseModal").data("downloadUrl", target)
//         $("#licenseModal .modal-dialog .modal-content").html(modalBody)
//         $("#licenseModal").modal('show')
//
//         $("#licenseModal div.modal-footer .btn-primary").click(function(e){
//           e.preventDefault()
//           createCookie("newsroom.accepted_license", "1", 365)
//
//           if(isGuestBasket) {
//             window.localBasketDataStore.post(postUrl)
//           } else {
//             window.location.href = url
//           }
//
//           $("#licenseModal").modal('hide')
//         })
//       })
//
//     return false
//   } else {
//     let url = $(this).prop("href")
//     if (url != null && url.indexOf("/guest") != -1 && window.localBasketDataStore) {
//       window.localBasketDataStore.post(url)
//       return false
//     }
//     else return true
//   }
// })

// empty basket localstorage before logging out
// $(document).on("click", "a#logout-link", function(e){
//    let logoutLink = $(this)
//    e.preventDefault()
//     window.localBasketDataStore.clear()
//     window.location.href = logoutLink.attr("href")
// })
