/*jshint esversion: 6 */

$(document).on('click', '[rel="toggle-class"]', function() {
  let $this = $(this);
  let $target = $this.data("target");
  let className = $this.data("toggle-class");

  $this.trigger("toggleClass.willToggle");
  $($target).toggleClass(className);
  $this.trigger("toggleClass.didToggle");

  return false;
});